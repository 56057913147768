@import "@polo/common/styles/index";

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-family: $font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
}

body {
    min-width: 0;
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);

    --theme-translucent-color: rgba(0, 0, 0, .1);
    --theme-translucent-hover-color: rgba(0, 0, 0, .2);

    @media (prefers-color-scheme: dark) {
        background: #16181d;
        --theme-translucent-color: rgba(255, 255, 255, .2);
        --theme-translucent-hover-color: rgba(255, 255, 255, .3);
    }
}

#app {
    flex: 1;
    display: flex;
    align-items: stretch;
    min-width: 0;
    min-height: 0;
}

#notifications {}

.no-horizontal-scrollbar {
    &::-webkit-scrollbar:horizontal {
        height: 0px;
    }
}

.main-gradient {
    
    background-image: linear-gradient(to bottom, #faf9fb, #f3f4f6);

    @media (prefers-color-scheme: dark) {
        background-image: linear-gradient(to bottom, #16181d, #1a1d23, rgb(29, 32, 39), rgb(32, 36, 44), rgb(36, 41, 50));
    }
}

.main-gradient-faded {
    transition: bottom ease-in-out 1s;

    background-image: linear-gradient(to bottom, #faf9fb, rgba(243, 244, 246, .9), rgba(243, 244, 246, .9), rgba(243, 244, 246, .5), rgba(243, 244, 246, 0));
    @media (prefers-color-scheme: dark) {
        background-image: linear-gradient(to bottom, #16181d, rgba(26, 29, 35, 0.9), rgba(29, 32, 39, .9), rgba(32, 36, 44, .5), rgba(36, 41, 50, 0));
    }

    @media (min-width: 1024px) {
        background-image: linear-gradient(to bottom, #faf9fb, rgba(243, 244, 246, .9), rgba(243, 244, 246, .4), rgba(243, 244, 246, 0), rgba(243, 244, 246, 0));
        @media (prefers-color-scheme: dark) {
            background-image: linear-gradient(to bottom, #16181d, rgba(26, 29, 35, 0.9), rgba(29, 32, 39, .4), rgba(32, 36, 44, 0), rgba(36, 41, 50, 0));
        }
    }
    
}

.manager-app {
    transition: filter .3s ease-in-out;

    &.--blurred {
        position: relative;
        &:before {
            content: ' ';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: rgba(0, 0, 0, .3);
        }
        filter: blur(5px);
    }
}