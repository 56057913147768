@mixin items-list() {

    @apply flex flex-row py-1;

    .__content {
        @apply flex flex-1 flex-nowrap items-start cursor-pointer min-w-0;

        .__title-container {
            @apply flex-grow min-w-0 overflow-hidden;

            .__title {
                @apply inline-block text-sm mr-3 flex-grow flex-1 whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold lg:text-base max-w-full;
            }
        }

        .__subtitle-container {
            @apply flex flex-nowrap text-xs text-gray-500 items-center;

            .__subtitle-item {
                @apply flex flex-nowrap items-center;

                &:not(:last-child) {
                    @apply mr-5;
                }
            }
        }
        
    }

    .__content {
        &:hover {
            .__title {
                @apply text-nord10 underline;
            }
        }
    }
}

@mixin minimal-scrollbar() {
    @apply bg-transparent bg-clip-text;
    transition: background-color .4s ease-in-out;
    
    &:hover {
        background-color: var(--theme-translucent-color);
    }

    &::-webkit-scrollbar {
        @apply w-4 h-4;
    }

    &::-webkit-scrollbar-thumb {
        border-width: 6px;
        background-color: inherit;
        @apply border-solid border-transparent rounded-xl bg-clip-padding;
    }

    &::-webkit-scrollbar-corner {
        @apply bg-transparent;
    }

    &::-webkit-scrollbar-thumb:vertical {
        @apply min-h-10;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        @apply min-w-10;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--theme-translucent-hover-color);
        @apply bg-clip-padding border-4 border-solid border-transparent;
    }
}